    .card-header {
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #DDDCDC;
        font-weight: bold;
        font-size: 20px;
    }

    .details-text {
        font-size: 13px;
        transition: transform 0.2s ease-in-out;
    }

    .details-text:hover {
        cursor: pointer;
        color: blue;
        transform: scale(1.2);
    }

    .card-body {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .top-portion {
        width: 100%;
        background: #ebeaeb86;
        height: 130px;
        padding: 15px;
        display: flex;
        justify-content: space-between;
    }

    .bottom-portion {
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .generator {
        height: 87px;
        width: 143px;
        /* background: rgba(217, 239, 248, 0.60); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }

    .generator>img {
        height: 120px;
    }

    .on-off-portion {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px;
    }

    .runtime-section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: .5rem;
    }

    .icon {
        color: black;
        margin-left: 2.5px;
        margin-top: 2px;
    }

    .icon-section {
        height: 30px;
        width: 30px;
        background: #C9DFFA;
        border-radius: 90%;
    }

    .runtime-text {
        font-size: 15px;
        font-weight: bold;
        color: #495584;
    }

    .runtime-value {
        height: 29px;
        width: 90px;
        background: #DEDCDC;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold
    }

    .bottom-top-portion,
    .bottom-bottom-portion {
        display: flex;
        justify-content: space-between;
    }

    .icon-title-section {
        display: flex;
        align-items: center;
        gap: 10px;
        min-width: 110px;
    }

    @media only screen and (max-width: 1537px) and (min-width: 1195px) {
        .item-section {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            justify-content: center;
        }

        .card-header {
            font-size: 15px;
        }

        .top-portion {
            height: 140px;
        }
    }

    @media only screen and (max-width: 1063px) and (min-width: 767px) {
        .item-section {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            justify-content: center;
        }

        .card-header {
            font-size: 15px;
        }

        .top-portion {
            height: 140px;
        }
    }

    @media only screen and (max-width: 600px) {
        .item-section {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            justify-content: center;
        }

        .card-header {
            font-size: 15px;
        }

        .top-portion {
            height: 140px;
        }
    }

    .on-off-switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* background: red; */
    }

    .wifi-status {
        justify-content: flex-start;
        margin-left: 8rem;
        margin-top: -10px;
    }

    /* The switch - the box around the slider */

    .switch-on-off {
        font-size: 15px;
        position: relative;
        display: inline-block;
        width: 4em;
        height: 2em;
    }

    /* Hide default HTML checkbox */

    .switch-on-off .chk {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */

    .slider-on-off {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(255, 255, 255);
        transition: .4s;
        border-radius: 30px;
    }

    .slider-on-off:before {
        position: absolute;
        content: "OFF";
        display: grid;
        place-content: center;
        height: 1.6em;
        width: 3.6em;
        border-radius: 20px;
        left: 0.17em;
        bottom: 0.22em;
        background-color: rgb(255, 0, 0);
        box-shadow: 0px 0px 3.7px black;
        transition: .4s ease-in-out;
        color: white;
    }

    .chk:checked+.slider-on-off:before {
        content: "ON";
        background-color: limegreen;
        box-shadow: inset 0px 0px 5px black;
    }

    .maintenance-alert-container {
        display: flex;
        justify-content: space-between;
    }

    .maintenance-details:hover {
        cursor: pointer;
        color: white;
        transform: scale(1.2);
    }

    .maintenance-details {
        /* color: red; */
        color: white;
        padding: 10px 25px;
        transition: transform 0.2s ease-in-out;
    }

    .maintenance-alert-container {
        background: rgb(193, 70, 70);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .maintenance-alert {
        color: white;
        font-size: 20px;
        padding: 10px 25px;
        font-weight: bold;
    }

    .columbia-switch {
        transform: scale(.7);
        /* background: green; */
    }

    .columbia-on-off-switch {
        display: flex;
        /* background: red; */
        padding-left: 15px;
        margin-top: -5px;
    }

    .columbia-text {
        margin-top: 7px;
        font-size: 15px;
        color: #495584;
    }

    @media only screen and (max-width: 1537px) {
        /* .columbia-text {
            display: none;
        } */
    }