table,
th,
tr,
td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px;
    text-align: center;
}

td {
    padding: 0px;
}

.loading-center {
    height: 100vh;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.room-card {
    border: 1px solid rgba(128, 128, 128, 0.286);
    padding: 20px;
    border-radius: 5px;
    height: 100%;
    text-align: center;
}

.room-name {
    text-align: center;
    width: 100%;
    background: rgba(212, 210, 210, 0.737);
    padding: 4px;
    border-radius: 5px;
    font-size: 22px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}

@media only screen and (max-width: 1652px) {
    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* gap: 10px;
        grid-auto-rows: minmax(100px, auto); */
    }
}

@media only screen and (max-width: 1154px) {
    .grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        grid-auto-rows: minmax(100px, auto);
    }
}

.threshold-status {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 23px;
}