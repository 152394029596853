.div_container {
    display: grid;
    grid-template-columns: 260px 131px 320px 190px 190px 76px 74px 120px;
    grid-template-rows: auto;
    grid-gap: 10px;
    overflow: scroll;
}

.flex_text_center {
    display: flex;
    align-items: center;
    justify-content: center;
}