.energy-td {
    text-align: left;
    padding: 5px;
}

.energy-details-td {
    padding: 5px;
}

.pieChart {
    width: 100%;
    height: 150px;
}

.chart-container {
    height: 250px;
    width: 250px;
}

.pie-chart-container {
    position: relative;
}

.custom-labels {
    position: absolute;
    top: 0;
    left: 100%;
    /* Adjust the left position as needed */
    transform: translate(10px, 0);
    /* Adjust the translation as needed */
    display: flex;
    flex-direction: column;
    font-size: 12px;
}