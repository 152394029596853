.red-light {
    height: 15px;
    width: 15px;
    background: red;
    border-radius: 90%;
    animation: red-blinking 1s infinite;
}

@keyframes red-blinking {
    0% {
        background: red;
    }
    20% {
        background: rgba(255, 0, 0, 0.529);
    }
    40% {
        background: rgba(255, 0, 0, 0.33);
    }
    80% {
        background: rgba(255, 0, 0, 0.529);
    }
    100% {
        background: red;
    }
}

.green-light {
    height: 15px;
    width: 15px;
    background: green;
    border-radius: 90%;
    animation: green-blinking 1s infinite;
}

@keyframes green-blinking {
    0% {
        background: green;
    }
    20% {
        background: rgba(0, 128, 0, 0.758);
    }
    40% {
        background: rgba(0, 128, 0, 0.444);
    }
    80% {
        background: rgba(0, 128, 0, 0.758);
    }
    100% {
        background: green;
    }
}

.yellow-light {
    height: 15px;
    width: 15px;
    background: rgb(164, 164, 20);
    border-radius: 90%;
    animation: yellow-blinking 1s infinite;
}

@keyframes yellow-blinking {
    0% {
        background: rgb(164, 164, 20);
    }
    20% {
        background: rgba(164, 164, 20, 0.781);
    }
    40% {
        background: rgba(164, 164, 20, 0.432);
    }
    80% {
        background: rgba(164, 164, 20, 0.781);
    }
    100% {
        background: rgb(164, 164, 20);
    }
}