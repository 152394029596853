.line-chart,
.bar-chart,
.calculation-portion {
    height: auto;
    /* width: 400px; */
    /* background: red; */
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #c4c9d4;
}

.bar-chart {
    margin-top: 20px;
}

.energy-table td {
    padding: 7.5px;
    flex-grow: 1;
}

.calculation-portion {
    /* margin-left: 20px !important; */
    /* width: calc(100% - 10px); */
    margin-right: -40px !important;
}

@media only screen and (max-width: 767px) {
    .calculation-portion {
        margin-top: 20px !important;
    }
}