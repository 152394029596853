.table-container {
    height: 400px;
    overflow: auto;
    position: relative;
}

.total-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Vertically center the content */
    position: sticky;
    bottom: 0;
    background-color: white;
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.2);
}

.total-cell {
    width: 100px;
    /* Adjust as needed */
    padding: 8px;
    /* Adjust as needed */
    text-align: center;
    /* Center the text horizontally */
}