@import "rsuite/dist/rsuite.css";

/* #root {
    padding: 10px;
} */

.date-search-submit-btn {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}