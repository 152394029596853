.toggleable-tabs-container {
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    width: 300px;
    /* Adjust width as needed */
}

.tab-buttons {
    display: flex;
}

.tab-buttons button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
}

.tab-buttons button.active {
    background-color: #ccc;
}

.tab-content {
    /* padding: 20px; */
    background-color: #f9f9f9;
}