.name-card {
    height: 110px;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name>span,
.generator-id>span {
    display: inline-block;
    width: 80px;
}

.name,
.generator-id {
    font-weight: bold;
}

.custom-padding {
    padding-top: 22px;
}

@media (max-width: 768px) {
    .info-card {
        padding: 10px;
        /* for padding in smallaer screens*/
    }
    /* .custom-padding {
        padding-top: 0px;
    } */
}

.gas-pump-icon {
    font-size: 5rem;
    color: #FFA219;
}

.gas-card-left-portion {
    display: flex;
    align-items: center;
}

.gas-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: bold;
    font-size: 15px;
}

.fuel-pump-seperator {
    height: 90%;
    border-left: 3px solid #CDCDCD;
}

.doughnut-container {
    height: 100%;
    border-radius: 5px;
}

.doughnut-chart {
    height: 250px;
}

.donut-chart-header,
.chart-title {
    padding: 20px 0px 0px 20px;
    font-size: 17px;
    font-weight: bold;
}

.doughnut-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
    padding: 10px;
}

.doughnut-card {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; */
    text-align: center;
}

.doughnut-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}

.main-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 48px 48px;
    height: 335px;
    border-radius: 5px;
}

.main-card-temp {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    align-items: center;
    justify-content: center;
    padding: 48px 48px;
    height: 335px;
    border-radius: 5px;
}

.voltage-value,
.card-title {
    font-weight: bold;
}

.value-card {
    height: 76px;
    width: 100%;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
}

.value-card-icon {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.line-chart {
    border: none;
}

.line-chart-container {
    border-radius: 5px;
}