        .list-table-container {
            padding-top: 1rem;
            width: 100%;
            overflow: scroll;
        }

        .list-table {
            width: 100%;
        }

        .list-table>tr>td {
            padding: 5px;
        }

        .clickable-td {
            color: blue;
            cursor: pointer;
            transition: transform .3s;
        }

        .clickable-td:hover {
            transform: scale(1.2);
        }

        .boiler-list-container {
            padding-top: 1rem;
        }

        .aman-card-title {
            padding: 20px 0 0 20px;
            font-weight: bold;
            font-size: 26px;
        }

        .aman-icon>img {
            /* background: red; */
            height: 99px;
            width: 140px;
        }

        .aman-body {
            padding: 20px 20px 20px 20px;
            /*top/right/bottom/left*/
            /* padding: 20px; */
            display: flex;
            gap: 3rem;
            height: 218px;
            /* width: 490px; */
        }

        .aman-text-container {
            display: flex;
            justify-content: space-between;
        }

        .text-only-container {
            display: flex;
            gap: 10px;
        }

        .aman-status {
            display: flex;
            align-items: center;
        }

        .aman-text {
            /* background: skyblue; */
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;
            font-size: 20px;
        }

        .text-value {
            font-weight: bold;
        }

        @media only screen and (max-width: 1650px) {
            .aman-card-title {
                font-size: 20px;
            }

            .aman-text {
                font-size: 16px;
            }
        }