.auth-body {
    /* background: gray; */
    background: url("../asset/background.jpg");
    background-size: 100% 100%;
    /* Cover the full width and height of the container */
    background-repeat: no-repeat;
    /* Prevent background image from repeating */
    background-position: center;
}

.btn-primary {}

.btn-primary:hover {
    background: rgba(143, 189, 86, .7) !important;
}