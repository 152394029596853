.badge {
    background-color: #D6FFDB;
    /* padding: 10px;
    margin-left: 10px; */
    /* border-radius: 5px; */
    display: flex;
    justify-content: space-between
}

.title,
.value {
    font-weight: bold;
}

.card {
    /* background: pink; */
    padding: 10px;
    /* height: 100px; */
    border-radius: 5px;
    margin-top: 20px;
}

.card_data_section {
    background: #ffff;
    min-height: 320px;
}

.card_title {
    font-weight: bold;
    font-size: 20px;
}

.card_table {
    padding-top: 10px;
}

.tr,
.td {
    border: 1px solid rgba(212, 201, 201, 0.848);
    text-align: left;
    font-size: 16px;
    padding: 2px;
}

.td_text_right {
    text-align: right;
    font-weight: bold;
}