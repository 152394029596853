/* .info-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
} */

.info-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
}

@media only screen and (max-width: 600px) {
    .info-container {
        display: flex;
        flex-direction: column;
        /* gap: 1rem; */
    }
}


/* .room-info-container {
    width: 300px !important;
   
} */