.search-container {
    display: flex;
    justify-content: space-between;
    /* background: red; */
}

.sensor-map-button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: green */
    margin-top: 4px;
}

.search-area {
    width: 100% !important;
    /* background: red; */
}

@media only screen and (max-width: 768px) {
    .search-container {
        display: block;
    }
}