.value-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1675px) {
    .gauge-container {
        width: 90%;
    }
}

@media only screen and (max-width: 1298px) {
    .gauge-container {
        width: 80%;
    }
}

@media only screen and (max-width: 1094px) {
    .gauge-container {
        width: 70%;
    }
}

@media only screen and (max-width: 767px) {
    .gauge-container {
        width: 90%;
    }
}