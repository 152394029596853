.gauge {
    background: #F7F8F9;
    /* background: gray; */
    margin-top: 40px;
}

.gauge-min-max-value {
    display: flex;
    justify-content: space-between;
    margin-top: -30px;
    /* background: blue; */
}

.sensor-name {
    text-align: center;
    margin-bottom: -35px;
}


/* .CircularProgressbar-text {
    color: black !important;
} */

.CircularProgressbar .CircularProgressbar-text {
    font-size: .7rem;
    text-anchor: middle;
    fill: #00040b !important;
}

.unit {
    text-align: center;
    margin-top: -80px
}