.premises_map_image {
    height: 20px;
    width: 20px;
    transition: transform 0.3s ease;
}


/* .premises_map_image:hover {
    transform: scale(10);
    overflow: visible;
    z-index: 99999999 !important;
} */