.div_container {
    display: grid;
    grid-template-columns: 131px 131px 76px 74px;
    grid-template-rows: auto;
    grid-gap: 10px;
    overflow: scroll;
}

.flex_text_center {
    display: flex;
    align-items: center;
    justify-content: center;
}